import { useEffect, useState } from "react";
import { appUrl } from "../core/config";
import { logger } from "../core";
import { MerchantSettingsManager } from "../../shared/merchantSettingsManager";
import fallbackSettings from "./fallbackMerchantSettings.json";

const initialState = {
  loading: true,
  error: null,
  settings: null,
};

export const useMerchantSettings = (merchantId) => {
  const [state, setState] = useState(initialState);

  const changeState = (newState) =>
    setState({
      ...state,
      ...newState,
    });

  useEffect(() => {
    async function fetchSettings() {
      if (!merchantId) {
        return;
      }

      try {
        const manager = new MerchantSettingsManager(appUrl);
        const settings = await manager.getConfigByID(merchantId);

        changeState({
          loading: false,
          settings,
        });
      } catch (ex) {
        logger.error(`useMerchantSettings(): returning fallback settings. ${ex}`);

        changeState({
          loading: false,
          settings: fallbackSettings,
        });
      }
    }

    fetchSettings();

    return () => {
      changeState(initialState);
    };
  }, [merchantId]);

  return [state.loading, state.error, state.settings];
};
