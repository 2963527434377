import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { threatMetrixConfig } from "../core/config";
import { isBrowser } from "../utils";
import threatmetrix from "../library/threat-metrix";
import { THREATMETRIX_CONFIG } from "../core/constants";
import { logger } from "../core";

export const useThreatMetrix = () => {
  const sessionStorageKey = THREATMETRIX_CONFIG.SESSION_ID;
  const profileCompleteEventName =
    THREATMETRIX_CONFIG.PROFILE_COMPLETE_EVENTNAME;
  const onMessage = THREATMETRIX_CONFIG.ON_MESSAGE;
  const profilingDomain = THREATMETRIX_CONFIG.PROFILING_DOMAIN;

  const [isLoading, setIsLoading] = useState(false);

  const profilingComplete = (event) => {
    if (isLoading) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    if (window.addEventListener) {
      window.addEventListener(
        profileCompleteEventName,
        profilingComplete,
        false
      );
    } else {
      window.attachEvent(onMessage, profilingComplete);
    }

    return () => {
      if (window.addEventListener) {
        window.removeEventListener(profileCompleteEventName, profilingComplete);
      } else {
        window.detachEvent(onMessage, profilingComplete);
      }
    };
  }, []);

  const startProfile = () => {
    setIsLoading(true);
    const sessionId = uuid();
    logger.info(`created session id ${sessionId}`)
    setSessionId(sessionId);

    threatmetrix.profile(profilingDomain, threatMetrixConfig.orgId, sessionId);
    //if event is not fired, set the isProfileLoaded true
    setTimeout(() => {
      setIsLoading(false);
    }, 10000);
  };

  const getSessionId = () => {
    if (!isBrowser()) {
      return null;
    }
    let sessionId = window.localStorage.getItem(sessionStorageKey)
    logger.info(`got session id ${sessionId}`)
    if (!sessionId){
      sessionId = uuid();
      logger.info(`got empty session id, hence returning ${sessionId}`)
    }
    return sessionId;
  };

  const setSessionId = (value) => {
    if (!isBrowser()) {
      return null;
    }
    return window.localStorage.setItem(sessionStorageKey, value);
  };

  const clearProfile = (value) => {
    if (!isBrowser()) {
      return null;
    }
    return window.localStorage.removeItem(sessionStorageKey);
  };

  return {
    getSessionId,
    startProfile,
    clearProfile,
    isLoading,
  };
};
