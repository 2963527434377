import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { Link, List, Stack, Text } from "basis";
import { StringParam, useQueryParam } from "use-query-params";

import { Container, LoadingMessage } from "../../../components";
import {
  FACTOR_NAME,
  prepareWidget,
  useCustomerAuth,
} from "../../../core/auth";
import {
  InvalidLinkRequest,
  LoginError,
  MerchantSettingsError,
  ROUTES,
  Scene,
} from "../../../layout/wallet";

import { logger } from "../../../core";
import { customerOktaConfig } from "../../../core/config";
import { MODE } from "../../../core/auth/constants";
import { CustomerOktaContainer } from "../../../layout/shared";
import { useMerchantSettings } from "../../../hooks";

const Login = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState();

  const location = useLocation();
  const widgetRef = useRef();

  const { start } = useCustomerAuth();

  const [merchantId] = useQueryParam("merchantId", StringParam);
  const [customerId] = useQueryParam("customerId", StringParam);
  const [signature] = useQueryParam("signature", StringParam);
  const [cancelURL] = useQueryParam("cancelUrl", StringParam);

  const [loading, error, merchantSettings] = useMerchantSettings(merchantId);

  const isValidRequest =
    merchantId?.length > 1 && customerId?.length > 1 && signature?.length > 1;

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    if (!isValidRequest) {
      return;
    }

    if (loginError) {
      return;
    }

    start();

    const widget = prepareWidget({
      oktaConfig: customerOktaConfig,
      mode: MODE.CUSTOMER,
      targetElement: widgetRef.current,
      title: "Login",
      usernamePlaceholder: "Latitude ID",
      redirectURL: `${ROUTES.REWARDS_LINK}${location.search}`,
      allowedFactors: [FACTOR_NAME.SMS],
      afterAuth: () => setAuthenticated(true),
      onError: (code, description) => {
        logger.error(`rewards link login error. ${code}: ${description}`);
        setLoginError(code);
      },
    });

    return () => {
      widget.off();
      widget.remove();
    };
  }, [location.search, start, loginError]);

  if (loading || isAuthenticated) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <LoadingMessage />
      </Scene>
    );
  }

  if (!isValidRequest) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <Container bg="white" margin="4 0" maxWidth={1100} shadow>
          <Container bg="white" margin="4 0" padding="10 6" maxWidth={800}>
            <InvalidLinkRequest />
          </Container>
        </Container>
      </Scene>
    );
  }

  if (error) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <Container bg="white" margin="4 0" maxWidth={1100} shadow>
          <Container bg="white" margin="4 0" padding="10 6" maxWidth={800}>
            <MerchantSettingsError />
          </Container>
        </Container>
      </Scene>
    );
  }

  return (
    <Scene
      theme={merchantSettings?.theme}
      merchantLogoURL={merchantSettings?.urlLogo}
    >
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Text align="left" textStyle="heading4">
            Unlock your benefits
          </Text>
        </Stack>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="10 6" maxWidth={400}>
          <Stack gap="4">
            <Text align="left" textStyle="heading4">
              Login
            </Text>

            <Text textStyle="body2" align="left">
              Log in to your {merchantSettings?.name} credit card with your
              Latitude ID.
            </Text>

            <Stack gap="2">
              <Text textStyle="body2" align="left">
                {merchantSettings?.name} can:
              </Text>

              {merchantSettings?.linkPermissions && (
                <List textStyle="body2">
                  {merchantSettings?.linkPermissions?.map((permission, idx) => (
                    <List.Item key={idx}>{permission}</List.Item>
                  ))}
                </List>
              )}
            </Stack>

            {loginError && <LoginError errorCode={loginError} />}

            <CustomerOktaContainer ref={widgetRef} />

            <Link href={cancelURL} newTab={false}>
              Cancel
            </Link>
          </Stack>
        </Container>
      </Container>
    </Scene>
  );
};

export default Login;
