// TODO: We can get rid of this manual shape validation once we move to TypeScript
export function validateSettingsStructure(config) {
  if (
    !config ||
    typeof config !== "object" ||
    !config.hasOwnProperty("name") ||
    !config.hasOwnProperty("title") ||
    !config.hasOwnProperty("desc") ||
    !config.hasOwnProperty("burnRate") ||
    !config.hasOwnProperty("urlLogo") ||
    !config.hasOwnProperty("compareLink") ||
    !config.hasOwnProperty("productHeight") ||
    !config.hasOwnProperty("theme") ||
    !config.hasOwnProperty("products") ||
    !config.hasOwnProperty("linkPermissions") ||
    !Array.isArray(config.products) ||
    !Array.isArray(config.linkPermissions)
  ) {
    throw new Error("Invalid root structure in merchant settings.");
  }

  // Validate theme structure
  const theme = config.theme;
  if (
    !theme ||
    typeof theme !== "object" ||
    !theme.hasOwnProperty("variant") ||
    !theme.hasOwnProperty("bgColor") ||
    !theme.hasOwnProperty("primaryColor") ||
    !theme.hasOwnProperty("highlightColor") ||
    !theme.hasOwnProperty("fontColor") ||
    !theme.hasOwnProperty("fontSize")
  ) {
    throw new Error("Invalid theme structure in merchant settings.");
  }

  // Validate products array structure
  const products = config.products;
  for (const product of products) {
    if (
      !product ||
      typeof product !== "object" ||
      !product.hasOwnProperty("id") ||
      !product.hasOwnProperty("name") ||
      !product.hasOwnProperty("urlLogo") ||
      !product.hasOwnProperty("learnLink") ||
      !product.hasOwnProperty("applyLink") ||
      !product.hasOwnProperty("annualFee") ||
      !product.hasOwnProperty("features") ||
      !Array.isArray(product.features)
    ) {
      throw new Error("Invalid product structure in merchant settings.");
    }
  }
}
