import { validateSettingsStructure } from "./validate";

export class MerchantSettingsManager {
  constructor(baseURL) {
    // In-memory cache to store fetched merchant settings
    this.cache = new Map();

    this.baseURL = baseURL;
    this.fetchPromises = new Map();
  }

  async getConfigByID(merchantID) {
    if (this.cache.has(merchantID)) {
      return this.cache.get(merchantID);
    }

    // Check if there's already a fetch in progress for the same merchantID
    if (this.fetchPromises.has(merchantID)) {
      return this.fetchPromises.get(merchantID);
    }

    // If not, initiate a new fetch
    const fetchPromise = this.fetchConfig(merchantID);
    this.fetchPromises.set(merchantID, fetchPromise);

    try {
      const config = await fetchPromise;

      // Validate structure
      validateSettingsStructure(config);

      // Cache the settings for future requests
      this.cache.set(merchantID, config);

      return config;
    } catch (error) {
      throw new Error(
        `Failed to fetch settings for merchant ID: ${merchantID}`
      );
    } finally {
      // Remove the fetch promise once it's completed
      this.fetchPromises.delete(merchantID);
    }
  }

  async fetchConfig(merchantID) {
    try {
      const response = await fetch(
        `${this.baseURL}/settings/merchants/${merchantID}.json`
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch settings for merchant ID: ${merchantID}`
        );
      }

      return response.json();
    } catch (error) {
      throw new Error(
        `Failed to fetch settings for merchant ID: ${merchantID}`
      );
    }
  }
}
